import React from 'react';
import Alert from '@material-ui/lab/Alert';
import { Box, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    info: {
        backgroundColor: theme.palette.background.grey,
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main
    },
    delaware_pageTop: {
        backgroundColor: `#ff9900`,
        border: `1px solid #000000`,
        color: `#660000`,
        width: '100%',
        marginRight: `33vw`,
    },
    delaware_afterStateSelect: {
        backgroundColor: `#ff9900`,
        border: `1px solid #000000`,
        color: `#660000`,
        marginLeft: `158px`,
        width: `412px`,
    }
}));

export const SuccessNotification = ({
    message
}) => (
    <Box mt={2} mb={2} component={Alert} severity="success">
        {message}
    </Box>
);

export const InfoNotification = ({
                                     children
                                 }) => {
    const classes = useStyles();

    return (
        <Box className={classes.info} icon={false} mt={2} mb={2} component={Alert} severity="info" color="primary">
            {children}
        </Box>
    );
}

export const DelawareNotification = ({
                                     cssSubClass, children
                                 }) => {
    const classes = useStyles();

    return (
        <Box className={classes[`delaware_${cssSubClass}`]} icon={false} mt={2} mb={2} component={Alert} severity="info" color="primary">
            {children}
        </Box>
    );
}