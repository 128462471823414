import React, { useState, useEffect } from 'react';
import Button from '../button';
import { TextField, Select, RadioGroup } from '../form';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import api from '../../helpers/api';
import { app, features } from '../../config';
import AccountForm from './AccountForm';
import MenuItem from '@material-ui/core/MenuItem';
import partyRepresenting from '../../config/partyRepresenting';
import { addDays, startOfHour, setHours, subDays } from 'date-fns';
import { DatePicker, TimePicker } from '../form/DatePicker';
import Alert from '@material-ui/lab/Alert'
import { NewFormControl } from '../form/FormControl';
import { Box, Divider, makeStyles, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import {DelawareNotification} from "../../helpers/notifications";

// See comment on removeSuppressedJudges() below re the following hack-constant:
const SUPPRESSED_JUDGE_IDS = [
    34, // Jo-Lynne Lee, California
    14, // Katharine L Mayer, Delaware
    11, // Diane Clarke Streett, Delaware
    13, // Andrea L Rocanelli, Delaware
    43, // Monday 2pm Civil Motion Calendar "judge", Delaware
    113, // Division L "judge", Florida
    29, // Keith L. Meyer, Florida
    28, // Paetra Brownlee, Florida
    113, // Division L "judge", Florida
    6, // 'Division L (previously Judge Leigh Hayes)' "judge", Florida
    21, // Mary M. Johnston, Delaware
    19, // William Carpenter, Delaware
    22, // Abigail Legrow, Delaware
    18, // Vivian Medinilla, Delaware
];

export const useStyles = makeStyles(theme => ({
    root: {
        border: `1px solid ${theme.palette.primary.main}`,
    },
    standardInfo: {
        backgroundColor: '#F4F8FF'
    },
    actionButton: {
        marginBottom: theme.spacing(2)
    },
    arrow: {
        position: 'relative',
        '&::before': {
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            position: 'absolute',
            top: '-27px',
            left: '-4px',
            borderTop: '0 solid transparent',
            borderBottom: `13px solid ${theme.palette.primary.main}`,
            borderRight: '13px solid transparent',
            borderLeft: '13px solid transparent'
        },
        '&::after': {
            content: '""',
            display: 'block',
            width: 0,
            height: 0,
            position: 'absolute',
            top: '-26px',
            left: '-3px',
            borderTop: '0 solid transparent',
            borderBottom: '12px solid #F4F8FF',
            borderRight: '12px solid transparent',
            borderLeft: '12px solid transparent'
        }
    },
    participants: {
        width: '155%',
    },
    participant: {
        display: 'flex',
    },
    participantTextfield: {
        fontSize: `14px`, // unclear why I have to explicitly set this...
        maxWidth: `175px`,
    },
    addRemoveRowBtn: {
        borderWidth: `0`,
        padding: `0 15px`,
        backgroundColor: `#2f4c75`,
        color: `#ffffff`,
        margin: `5px 0 20px 0`,
        borderRadius: `3px`,
        maxHeight: `25px`,
        width: `70px`,
        fontSize: `11px`,
    },
    addRemoveRowBtnHidden: {
        visibility: `hidden`,
    },

    sideNote: {
        width: `30vw`,
        height: `20px`,
        marginTop: `8px`,
        overflow: `visible`,
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            marginLeft: theme.spacing(2),
        },
        [theme.breakpoints.down('md')]: {
            fontSize: `1vw`,
            width: `25vw`,
        },
        ["@media (max-width:1162px)"]: {
            width: `20vw`,
        },
        ["@media (max-width:1080px)"]: {
            width: `100%`,
            fontSize: `12.5px`,
            paddingLeft: `142px`,
            marginTop: `-10px`,
            marginBottom: `10px`,
            height: `auto`,
        },
        [theme.breakpoints.down('sm')]: {
            paddingLeft: `0px`,
            marginLeft: `0px`,
        },
    },

    judgeInfo: {
        marginBottom: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
            marginBottom: 0,
            marginLeft: theme.spacing(2)
        }
    }
}));

export default function Information({
                                        states,
                                        judges,
                                        statePulldownRef,
                                        setJudges,
                                        showDelawareNote,
                                        onFormSubmit,
                                        formData
                                    } = {
    states: [],
    judges: []
}) {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const classes = useStyles();
    const currentDate = new Date();
    const defaultDate = (formData && formData.scheduledDate) ? formData.scheduledDate : startOfHour(setHours(addDays(currentDate, 1), currentDate.getHours() + 1));
    const defaultTime = (formData && formData.scheduledDate) ? formData.scheduledDate : defaultDate;
    const [selectedState, setSelectedState] = useState();
    const { register, handleSubmit, control, watch, errors, formState } = useForm({
        defaultValues: {
            /* re "participantsAppearing": someone started down this road before; I'm ignoring whatever's been
             implemented so far for multi-participants feature because I have no visibility into how it might
             have been implemented (presumably only partially) - leaving this though: */
            participantsAppearing: [{ name: '', email: '', phone: '' }],
            accountName: '',
            firmName: '',
            accountEmail: '',
            createAccount: true,
            caseNumber: '',
            caseName: '',
            hearingDate: defaultDate,
            hearingTime: defaultTime,
            stateId: '',
            judgeId: '',
            partyRepresenting: '',
            hearingType: '',
            // old text field (one for potentially several participants):
            participantAppearing: '',
            ...formData
        }
    });
    const { fields: participants, append, remove } = useFieldArray({
        control,
        name: 'participantsAppearing'
    });
    const createAccount = watch('createAccount');
    const selectedStateId = watch('stateId');
    const courtReporterRequested = watch('courtReporterRequested');
    const aiTranscriptRequested = watch('aiTranscriptRequested');
    const audioRecordingRequested = watch('audioRecordingRequested');
    const {
        isSubmitting
    } = formState;
    const populatePartyRepresenting = (selectedState && selectedState.abbreviation === `ME`);

    useEffect(() => {
        if (selectedStateId) {
            setSelectedState(states.find(state => state.id === Number(selectedStateId)));

            api('/api/judges', {
                query: {
                    stateId: selectedStateId
                }
            }).then(judges => setJudges(removeSuppressedJudges(judges)));
        }
    }, [states, selectedStateId, setJudges]);

    return (
        <form onSubmit={handleSubmit(onFormSubmit)}>
            <Box mt={4} mb={4}>
                <Box mb={3}>
                    <Typography variant="h6" gutterBottom>Your Information</Typography>
                    <Divider />
                </Box>
                <AccountForm
                    control={control}
                    errors={errors}
                    createAccount={createAccount}
                />
            </Box>
            <Box mb={4}>
                <Box mb={3}>
                    <Typography variant="h6" gutterBottom>Case Information</Typography>
                    <Divider />
                </Box>
                <Controller
                    control={control}
                    name="caseNumber"
                    rules={{ required: 'Please enter a case number' }}
                    render={props =>
                        <TextField
                            label="Case Number"
                            placeholder="Case Number"
                            errors={errors}
                            {...props}
                        />
                    }
                />
                <Controller
                    control={control}
                    name="caseName"
                    rules={{ required: 'Please enter a case name' }}
                    render={props =>
                        <TextField
                            label="Case Name"
                            placeholder="Case Name"
                            errors={errors}
                            {...props}
                        />
                    }
                />
                <Controller
                    name="hearingDate"
                    control={control}
                    render={props =>
                        <Box display="flex" flexWrap="wrap">
                            <DatePicker
                                label="Date"
                                placeholder="mm/dd/yyyy"
                                errors={errors}
                                minDate={subDays(defaultDate, 1)}
                                {...props}
                            />
                            <Box className={classes.sideNote}>
                                <>
                                    If you are scheduling a proceeding for the same day, or within 24 hours, please either
                                    email <a target="_blank" href="mailto:scheduling@courtscribes.com"
                                             style={{color: `#000000`}}
                                >scheduling@courtscribes.com</a> or
                                    call <a color="primary" href="tel:833-727-4237" style={{color: `#000000`}}
                                >833-727-4237</a> <i>after</i> you have received the confirmation from this website
                                    to ensure that the booking has been received and set up. Please have the job number ready
                                    when contacting our staff.
                                </>
                            </Box>
                        </Box>
                    }
                />
                <Controller
                    name="hearingTime"
                    control={control}
                    rules={{ required: 'Please select a time' }}
                    render={props =>
                        <TimePicker
                            label="Time"
                            placeholder="hh:mm"
                            errors={errors}
                            minDate={defaultTime}
                            {...props}

                        />
                    }
                />
                <Controller
                    name="stateId"
                    control={control}
                    rules={{ required: 'Please select a state' }}
                    render={props =>
                        <Select inputRef={statePulldownRef} label="State" name="stateId" errors={errors} {...props}>
                            <MenuItem value="">Select a state</MenuItem>
                            {(states && states.length > 0) && states.map(state => <MenuItem key={state.id} value={state.id}>{state.name}</MenuItem>)}
                        </Select>
                    }
                />
                {(showDelawareNote) && (
                    <DelawareNotification cssSubClass={`afterStateSelect`}>CourtScribes <b>now offers court reporting</b> in Delaware.{` `}
                        <a style={{color: `#330000`}}
                           href={`mailto:scheduling@courtscribes.com?subject=${window.encodeURIComponent('Request a Court Reporter')}`}
                           target={`_blank`}>Click here</a> to schedule.</DelawareNotification>
                )}
                {(selectedState && selectedState.courtReporterServiceEnabled) &&

                    <>
                        <Box display="flex" flexWrap="wrap">
                        <Controller
                            name="courtReporterRequested"
                            control={control}
                            rules={{ required: 'Please make a choice' }}
                            // courtReporterRequested: at start, neither true nor false is chosen per D Blaze, October 2023::
                            defaultValue={formData ? formData.courtReporterRequested : ''}
                            render={props =>
                                <RadioGroup
                                    errors={errors}
                                    label="Do you need a court reporter?"
                                    options={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: 'false' }
                                    ]}
                                    {...props}
                                />
                            }
                        />
                        <Box className={classes.sideNote}>
                            <>
                                <br/>
                                A.I. rough drafts are <b>for internal use only</b>;
                                a court reporter must be present at the hearing.
                                Drafts are generated using speech-to-text technology, with additional human editing.
                                {` `}<i>Cost:</i> $50 for up to 30 minutes; $75 for 30 - 60 minutes; $95 for over 60 minutes.
                            </>
                        </Box>
                        </Box>

                        {courtReporterRequested === 'true' &&
                            <NewFormControl>
                                <Alert icon={false} severity="info" classes={{
                                    root: classes.root,
                                    standardInfo: classes.standardInfo
                                }}>
                                    <span className={classes.arrow} />
                                    <Typography color="primary" variant="body2">Your are requesting for a court reporter.</Typography>
                                    <Typography color="primary" variant="body2">CourtScribes will reach out to you after you finish scheduling this hearing.</Typography>
                                </Alert>
                            </NewFormControl>
                        }
                        <Box className={`mainSchedulePageAiBox`} display="flex" flexWrap="wrap">
                            <Controller
                                name="aiTranscriptRequested"
                                control={control}
                                rules={{ validate: (value) => {
                                        if (courtReporterRequested === `false`) {
                                            if (value !== `false`) {
                                                return `You cannot request an A.I. transcript without requesting a court reporter.`;
                                            }
                                        }
                                } }}
                                defaultValue={formData ? formData.aiTranscriptRequested : 'false'}
                                render={props =>
                                    <RadioGroup
                                        errors={errors}
                                        label="Do you need an A.I. rough draft?"
                                        options={[
                                            { label: 'Yes', value: 'true' },
                                            { label: 'No', value: 'false' }
                                        ]}
                                        {...props}
                                    />
                                }
                            />
                        </Box>
                        {aiTranscriptRequested === 'true' &&
                            <NewFormControl>
                                <Alert icon={false} severity="info" classes={{
                                    root: classes.root,
                                    standardInfo: classes.standardInfo
                                }}>
                                    <span className={classes.arrow} />
                                    <Typography color="primary" variant="body2">Your are requesting an A.I. rough draft.</Typography>
                                    <Typography color="primary" variant="body2">CourtScribes will reach out to you after you finish scheduling this hearing.</Typography>
                                </Alert>
                            </NewFormControl>
                        }
                    </>

                }
                {(false && selectedState && selectedState.audioRecordingServiceEnabled) &&
                    <>
                        <Controller
                            name="audioRecordingRequested"
                            control={control}
                            rules={{ required: 'Please make a choice' }}
                            defaultValue={formData ? formData.audioRecordingRequested : 'false'}
                            render={props =>
                                <RadioGroup
                                    errors={errors}
                                    label="Would you like to order the audio recording for this proceeding?"
                                    options={[
                                        { label: 'Yes', value: 'true' },
                                        { label: 'No', value: 'false' }
                                    ]}
                                    {...props}
                                />
                            }
                        />
                        {audioRecordingRequested === 'true' &&
                            <NewFormControl>
                                <Alert icon={false} severity="info" classes={{
                                    root: classes.root,
                                    standardInfo: classes.standardInfo
                                }}>
                                    <span className={classes.arrow} />
                                    <Typography color="primary" variant="body2">You will receive an email from transcripts@courtscribes.com for more information.</Typography>
                                </Alert>
                            </NewFormControl>
                        }
                    </>
                }
                <Controller
                    name="judgeId"
                    control={control}
                    rules={{ required: 'Please select a judge' }}
                    render={props =>
                        <Box display="flex" flexWrap="wrap">
                            <Select label="Judge" name="judgeId" errors={errors} disabled={!selectedState} {...props}>
                                <MenuItem value="">Select Judge</MenuItem>
                                {(judges && judges.length > 0) && judges.map((judge => <MenuItem key={judge.id} value={judge.id}>{judge.displayName}</MenuItem>))}
                            </Select>
                            <Box className={classes.judgeInfo}>
                                <Typography variant="body2" style={{ fontWeight: 600 }}>Don't see your judge?</Typography>
                                <Typography variant="body2">Please give us a call at <Typography variant="body2" color="primary" component="a" href="tel:833-727-4237">833-727-4237</Typography></Typography>
                            </Box>
                        </Box>
                    }
                />
                {features.showParticipantListField && (
                    <NewFormControl displayOnly label="Participant(s) Appearing" errors={errors}>
                        <div className={classes.participants}>
                            {participants.map((participant, index) => (
                                <div className={classes.participant} key={participant.id}>
                                    <Controller
                                        control={control}
                                        name={`participantsAppearing${index}name`}
                                        rules={{ required: 'Please enter a name' }}
                                        defaultValue={participant.name}
                                        render={props =>
                                            <TextField
                                                key={participant.id}
                                                showLabel={false}
                                                placeholder="Name"
                                                autocomplete="name"
                                                errors={errors}
                                                className={`${classes.participantTextfield}`}
                                                {...props}
                                            />
                                        }
                                    />
                                    <Controller
                                        control={control}
                                        name={`participantsAppearing${index}email`}
                                        rules={{ required: 'Please enter an email' }}
                                        defaultValue={participant.email}
                                        render={props =>
                                            <TextField
                                                key={participant.id}
                                                type="email"
                                                showLabel={false}
                                                placeholder="Email"
                                                autocomplete="email"
                                                errors={errors}
                                                className={`${classes.participantTextfield}`}

                                                {...props}
                                            />
                                        }
                                    />
                                    <Controller
                                        control={control}
                                        name={`participantsAppearing${index}phone`}
                                        rules={{}}
                                        defaultValue={participant.phone}
                                        render={props =>
                                            <TextField
                                                key={participant.id}
                                                showLabel={false}
                                                placeholder="Phone"
                                                autocomplete="tel-national"
                                                errors={errors}
                                                className={`${classes.participantTextfield}`}
                                                {...props}
                                            />
                                        }
                                    />
                                    {index === participants.length - 1 ?
                                        <button className={`${classes.addRemoveRowBtn}`} type="button" onClick={() => append({ name: '', email: '', phone: '' })}>Add</button>
                                        :
                                        <button className={classes.addRemoveRowBtn} type="button" onClick={() => remove(index)}>Remove</button>
                                    }
                                </div>
                            ))}
                        </div>
                    </NewFormControl>
                )}
                {!features.showParticipantListField && (
                    <Controller
                        control={control}
                        name="participantAppearing"
                        rules={{ required: 'Please enter a hearing participant' }}
                        render={props =>
                            <TextField
                                label="Participant(s) Appearing"
                                placeholder="Name"
                                errors={errors}
                                {...props}
                            />
                        }
                    />
                )}
                {
                    populatePartyRepresenting
                        ? <></>
                        : <Controller
                            name="partyRepresenting"
                            control={control}
                            rules={{ required: 'Please select a representing party' }}
                            render={props =>
                                <Select
                                    label="Party Representing"
                                    name="partyRepresenting"
                                    errors={errors}
                                    {...props}
                                >
                                    <MenuItem value="">Select party representing</MenuItem>
                                    {partyRepresenting.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
                                </Select>
                            }
                        />
                }

                <Controller
                    name="hearingType"
                    control={control}
                    rules={{ required: 'Please select a hearing type' }}
                    render={props =>
                        <Select label="Type of Hearing" name="hearingType" errors={errors} {...props}>
                            <MenuItem value="">Select hearing</MenuItem>
                            {app.hearingTypes.map(hearingType => <MenuItem key={hearingType} value={hearingType}>{hearingType}</MenuItem>)}
                        </Select>
                    }
                />
            </Box>
            <Box mb={4}>
                <NewFormControl row>
                    <Button className={classes.actionButton} color="primary" type="submit" fullWidth={isMobile} loading={isSubmitting} disabled={isSubmitting}>Continue</Button>
                </NewFormControl>
            </Box>
        </form>
    );
}

/**
 * This is a hack to suppress specific judges from the pulldown.  The long-term solution is to either add a "showInUI"
 * field to the judge model (assuming the goal is to keep the judge in the database but disallow future hearings to
 * be scheduled for them).
 * @param judges
 */
function removeSuppressedJudges(judges) {
    // console.info(judges);
    return judges.filter(judge => SUPPRESSED_JUDGE_IDS.indexOf(judge.id) === -1);
}

